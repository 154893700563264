<template>
  <div class="row match-height mt-1">
    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
      <select class="form-control">
        <option>Requisition Date</option>
        <option>Approval Date</option>
        <option>Expected receive Date</option>
      </select>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
      <input class="form-control" placeholder="From" type="date">
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
      <input class="form-control" placeholder="From" type="date">
    </div>
  </div>
  <div class="row match-height mt-1">
    <div class="col-12 col-sm-6 col-md-4 col-lg-8">
      <label>Requester</label>
      <input type="text" class="form-control" placeholder="Requester">
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
      <label>Department</label>
      <input type="text" class="form-control" placeholder="Department">
    </div>
  </div>
  <div class="row match-height mt-1">
    <div class="col-12 col-sm-6 col-md-4 col-lg-8">
      <label>Project</label>
      <input type="text" class="form-control" placeholder="Project">
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
      <label>Item</label>
      <input type="text" class="form-control" placeholder="Item">
    </div>
  </div>
  <div class="row match-height mt-1">
    <div class="col-12 col-sm-6 col-md-4 col-lg-8">
      <label>Status</label>
      <input type="text" class="form-control" placeholder="Status">
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
      <br>
      <input type="text" class="form-control" placeholder="Quick Search">
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-1">
      <br>
      <button class="btn btn-primary" @click="handleClick">Go</button>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['onClickGo']);

function handleClick() {
  emit('onClickGo');
}
</script>
