<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Requisition Date</th>
          <th>Requisition No</th>
          <th>Project</th>
          <th>Department</th>
          <th>Total</th>
          <th>Allocation Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in requisitionList.data" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.requisition_number }}</td>
          <td>{{ item.project.name }}</td>
          <td>{{ item.department.name }}</td>
          <td>{{ item.total_amount }}</td>
          <td>{{ item.allocation_status }}</td>
          <td>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item.id)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
            <button v-if="item.pending_general_count > 0" class="btn btn-primary btn-sm"
                    @click="allocate(item.id)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-save"></i> Allocate
            </button>
            <button v-else class="btn btn-secondary btn-sm"
                    @click="allocate(item.id)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-eye"></i> View
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!requisitionList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref } from 'vue';
  import {useRoute, useRouter} from "vue-router";
  import { useStore } from 'vuex';
  import handleRequisitions from "@/services/modules/procurement/requisition";
  import handleBarcode from "@/services/modules/barcode";
  import handleCompany from "@/services/modules/company";
  import requisitionPdfPrinter from '@/services/utils/requisitionPdfPrinter';

  const props = defineProps({
    requisitionList: {
      type: Object
    }
  })

  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const barcodeQuery = ref('');
  const { fetchSingleRequisition } = handleRequisitions();
  const { fetchBarcode } = handleBarcode();
  const { exportToPDF } = requisitionPdfPrinter();
  const barcode = ref('');
  const qrcode = ref('');
  const { fetchCompanyInfo } = handleCompany();

  let requisition = reactive({});
  let companyInfo = reactive({})

  const goToPrint = async (id) => {

    const user = store.state.user.user;
    const companyQuery = `?company_id=${route.params.companyId}`;
    
    await fetchSingleRequisition(id, companyQuery).then((res) => {
      if (res.status) {
        
        requisition = res.data;

        let qrcodeData = JSON.stringify({
          requisition_no: requisition.requisition_number,
          requisition_date: requisition.date,
          created_by: user.name,
          created_date_time: formatDate(new Date())
        })

        barcodeQuery.value = `?code=${requisition.requisition_number}&qrcode=${qrcodeData}`;
      }
    })
    .then(() => {
      fetchBarcode(barcodeQuery.value).then((res) => {
          barcode.value = res.barcode;
          qrcode.value = res.qrcode
      }).then(() => {
        exportToPDF(companyInfo, requisition, barcode.value, qrcode.value, user.name)
      })
    })
    .catch((err) => {
      console.log("Something went wrong");
    });
  }

  const formatDate = (date) => {
    var formattedDate = date.toISOString().split('T')[0];
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

    return formatToDdMmYy(formattedDate) + ' ' + formattedTime
  }

  const formatToDdMmYy = (date) => {
    var dat = date.split('-');
    return `${dat[2]}-${dat[1]}-${dat[0]}`;
  }

  const allocate = (id) => {
    let param = {
      companyId: route.params.companyId,
      moduleId: route.params.moduleId,
      menuId: route.params.menuId,
      pageId: route.params.pageId,
      requisitionId: id,
    }

    router.push({
      name: 'pr-allocation-add',
      params: param,
      query: route.query
    })
  }

  const setCompanyInfo = async () => {
    await fetchCompanyInfo(route.params.companyId).then((res) => {
      companyInfo = res.data;
    }).catch((error) => {
      console.log(error);
    });
  }

  onMounted(() => {
    setCompanyInfo();
  })

</script>

